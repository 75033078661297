export const Features = [
  {
    image: 'https://www.mlytics.com/wp-content/uploads/2021/11/icon_Automated-orchestration.png',
    title: 'smartLoadBalancing.section3.card1.title',
    content: 'smartLoadBalancing.section3.card1.content'
  },
  {
    image: 'https://www.mlytics.com/wp-content/uploads/2021/11/icon_Data-driven.png',
    title: 'smartLoadBalancing.section3.card2.title',
    content: 'smartLoadBalancing.section3.card2.content'
  },
  {
    image: 'https://www.mlytics.com/wp-content/uploads/2021/11/icon_Zero-downtime.png',
    title: 'smartLoadBalancing.section3.card3.title',
    content: 'smartLoadBalancing.section3.card3.content'
  }
]
